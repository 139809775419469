<template>
  <b-modal
    ref="modal-bill-excel"
    hide-footer
    :title="titleExcel"
    id="modal-bill-excel"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <b-form-group label="Danh sách cần xuất:">
          <b-form-radio-group v-model="selectedListExcel">
            <b-form-radio value="all">Tất cả các trang</b-form-radio>
            <b-form-radio value="filter">Theo bộ lọc</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Misa:">
          <b-form-checkbox v-model="selectedMisa">Xuất theo hoá đơn misa</b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-row v-if="selectedListExcel === 'all'">
          <b-col md="4">
            <b-form-group>
              <label>Từ ngày:</label>
              <date-picker
                placeholder="Từ ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.fromDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Đến ngày:</label>
              <date-picker
                placeholder="Tới ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.toDate"
              ></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      :show="isDownloading"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      @hidden="$refs.button.focus()"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            variant="light"
            small
            type="grow"
          ></b-spinner>
        </div>
      </template>
      <b-button
        variant="primary"
        size="sm"
        @click="exportToExcelStream"
      >
        <v-icon
          small
          class="text-white"
          v-b-tooltip
        >mdi-download</v-icon>
        <strong>Xuất Excel</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('modal-bill-excel')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
// import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
// import { minLength } from 'vuelidate/lib/validators';

// import { currencyMask, unMaskPrice } from './../../utils/common';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import { makeToastFaile } from '../../../utils/common';
// import { ORDER_STATUS, ORDER_CANCEL_STATUS } from '../../../utils/constants';
import ApiService from '@/core/services/api.service';
import fileDownload from '../../../utils/file-download';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import moment from 'moment';
export default {
  props: ['param', 'mode'],
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      selectedListExcel: 'all',
      apiParams: null,
      titleExcel: 'Xuất excel hoá đơn bán lẻ',
      types: [],
      isDownloading: false,
      selectedMisa: false,
    };
  },
  components: {
    datePicker,
  },
  computed: {},
  created() {},
  watch: {
    $props: {
      handler() {
        switch (this.mode) {
          case 1:
            this.titleExcel = 'Xuất excel hoá đơn bán lẻ';
            this.types = [1, 4];
            break;
          case 2:
            this.titleExcel = 'Xuất excel hoá đơn bán buôn';
            this.types = [2];
            break;
          case 3:
            this.titleExcel = 'Xuất excel hoá đơn trả hàng';
            this.types = [3];
            break;
          default:
            this.titleExcel = 'Không xác định';
            break;
        }
        this.apiParams = { ...this.param };
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    checkPermissions,
    async exportToExcelStream() {
      this.isDownloading = true;

      const apiParams = this.getParamFilters();

      if (!apiParams) {
        this.isDownloading = false;
        return;
      }
      try {
        const response = await ApiService.query('bills/export-excel', {
          params: apiParams,
          responseType: 'blob',
        });
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      } catch (error) {
        const responseObj = JSON.parse(await error.response.data.text());
        return makeToastFaile(responseObj.message || 'Lỗi');
      } finally {
        this.isDownloading = false;
        this.$nprogress.done();
      }
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    getParamFilters() {
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';
      const fromDate = this.apiParams.fromDate
        ? moment(this.apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : '1991-01-01';

      const toDate = this.apiParams.toDate
        ? moment(this.apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : moment().format('YYYY-MM-DD');

      const apiParams = {
        fromDate: fromDate,
        toDate: toDate,
        types: this.types,
        selectedMisa: this.selectedMisa || null,
      };

      if (this.selectedListExcel === 'filter') {
        const diff = this.getDaysDiff(apiParams.fromDate, apiParams.toDate);

        if (
          diff > 100 &&
          (!this.apiParams.searchCustomer ||
            this.apiParams.searchCustomer.trim() === '')
        ) {
          makeToastFaile('Vui lòng chọn khoảng thời gian trong vòng 3 tháng!');
          return null;
        }
        const productSearch = this.apiParams.productSearch || '';

        if (productSearch.length < 5 && productSearch.length > 1) {
          makeToastFaile('Tên sản phẩm phải từ 5 kí tự trở lên');
          return null;
        }

        const criteriaIds = this.param.criteriaIds.map(({ code }) => code);

        const storeIds = this.param.storeIds.map(({ code }) => code);

        return {
          ...this.apiParams,
          ...apiParams,
          productSearch: productSearch ? productSearch.trim() : null,
          criteriaIds: criteriaIds,
          storeIds: storeIds,
          selectedMisa: this.selectedMisa || null,
        };
      }

      const diff = this.getDaysDiff(apiParams.fromDate, apiParams.toDate);
      if (diff > 32) {
        makeToastFaile('Vui lòng chọn khoảng thời gian trong vòng 1 tháng!');
        return null;
      }

      return apiParams;
    },
    getDaysDiff(startDate, endDate, dateFormat = 'YYYY-MM-DD') {
      const getDateAsArray = (date) => {
        return moment(date.split(/\D+/), dateFormat);
      };
      return (
        getDateAsArray(endDate).diff(getDateAsArray(startDate), 'days') + 1
      );
    },
  },
};
</script>
